import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
    <main className='home-page'>
        <Container as="main" className='content-index'>
            <Row className='left-side'>
                <Col md={true}>
                    <div className = 'heading'>
                        <h3 className='message'>Ciao!<br />I'm <span className='name'><strong>Veronica De Ronzi</strong></span>, a <span className='name'><strong>Junior Full Stack Developer</strong></span> with a burning desire to learn!</h3>
                        <p className='first-page lead'>Take a look at my work and my world!
Enjoy!</p>
                        
                        </div>
                </Col>
                        <Col md="6" className='my-projectsign'>
                            {/* <img className='w-100' src="/images/door.png" alt= "Game" /> */}
                            <Link to="/projects"   id ="all-btn">  <img className='w-100' src="/images/rightsign.png" alt= "Sign" id= "sign-door"/></Link>
                        </Col>
            </Row>
            <Row>
                <Col className='contact'>
                    <h3 className='contactme'>Contact Me</h3>     
                    <a className='text-white text-decoration-none' href='https://github.com/verodr' target="_blank" rel="noopener noreferrer"><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg" alt="The logo icon for GitHub" className="con-icons" id="git-home"/></a>
                    <a className='text-white text-decoration-none' href='mailto:veronica.deronzi@gmail.com' target="_blank" rel="noopener noreferrer"><img src="/images/mail.png"alt="The logo icon for GitHub" className="con-icons" /></a>
                    <a href="https://www.linkedin.com/in/veronica-de-ronzi-019173185/" target="_blank" rel="noreferrer"><img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-plain.svg" alt="The logo icon for Linkedin" className="con-icons" id="linkedin"/></a>
                </Col>
            </Row>

        </Container>
    </main>
    )

}

export default Home