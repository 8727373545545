import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//components
import Footer from './components/Footer'
import Home from './components/Home'
import PageNavbar from './components/PageNavbar'
import Skills from './components/Skills'
import Art from './components/Art'
import ProjectsPage from './components/ProjectsPage'
import AboutMe from './components/AboutMe'
import NotFound from './components/NotFound'



const App = () => {
    return (
    <div className= 'App'>
      <BrowserRouter>
        <PageNavbar />
        <Routes>
          <Route path = '/' element = {<Home/>} />
          <Route path = '/projects' element = {<ProjectsPage/>} />
          {/* <Route path = '/contact' element = {<ContactMe/>} /> */}
          <Route path = '/about' element = {<AboutMe/>} />
          <Route path = '/art' element = {<Art/>} />
          <Route path = '/skills' element = {<Skills/>} />
          <Route path = '*' element = {<NotFound/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>

  ) 
}

export default App;
