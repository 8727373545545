
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
const Art = ()=> {
    return(
    <div className='list-wrapper'>
    <Container className='list-container content-index text-center'> 
      <h1 className='text-center mb-4'> Portrait Collection </h1>
      <p>Made freehand using 'Note Saver' on Lenovo YB1-X90F</p>
      <hr/>
      <Row>
              <Col md="6" lg="6" className='mb-4'>
                  <Card className='list-card'>
                    <img src="/images/meg3.png" alt= "Meg" />
                  </Card>
              </Col>
              <Col md="6" lg="6" className='mb-4'>
                  <Card className='list-card'>
                    <img src="/images/franz.png" alt= "Franz" />
                  </Card>
              </Col>
              <Col md="6" lg="6" className='mb-4'>
                  <Card className='list-card'>
                    <img src="/images/cris.png" alt= "Cris" />
                  </Card>
              </Col>
      </Row>
      <h1 className='text-center mb-4'> Lost Things' Heaven Collection </h1>
      <p>Made freehand using 'Note Saver' on Lenovo YB1-X90F</p>
      <hr/>
      <Row>
              <Col md="6" lg="6" className='mb-4'>
                  <Card className='list-card'>
                    <img src="/images/bike.png" alt= "Game" />
                  </Card>
              </Col>
             
      </Row>
    </Container>
  </div>

    )
}
export default Art