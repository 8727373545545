
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ProjectsPage = () => {
    return (
        <Container as="main">
            <Row id="projects-rig">
            <Col md="6">
                <img className='w-100' src="/images/planet.png" alt= "Planet" />
            </Col>
            <Col md="6" className='all-proj'>
              {/* Description */}
                <h2>PLANET EARTH IS CALLING</h2>
                <hr />
                <h4>Solo project - 7 days</h4>
                <hr />
              {/* Origin */}
                <p>I created a fully mobile responsive CRUD application built using Python Django and PostgreSQL for the back-end and React for the front-end and which I made fully responsive thanks to the use of Bootstrap a SASS for the styling.
                    Through this app, people from all over the world can document the effects of climate change by uploading images and, if they wish, they can start fundraising.</p>
                <hr />
              {/* Added by */}
                <h4><span>🔧</span> Main Technologies Used:</h4>
                <p>JavaScript - HTML5 - CSS - SASS - React - Express - Insomnia - React Bootstrap -Python - Django REST Framework - Psycopg2 - PyJWT - TablePlus - Netlify - Cloudinary - GitHub</p>
                <hr />
                <div className='btn-flex'>
                <a className='text-dark text-decoration-none' href='https://github.com/verodr/Project-4-Planet' target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
          </svg></a>
            <a className='text-dark text-decoration-none btn dark' href='https://planet-earth-is-calling.herokuapp.com' target="_blank" rel="noopener noreferrer">Website
              </a> 
                </div>
            </Col>
        </Row>
        <Row id="projects-rev">
            <Col md="6">
              <img className='w-100' src="/images/readIt.png" alt= "Planet" />
            </Col>
            <Col md="6" className='all-proj'>
              {/* Description */}
              <h2><span></span>Read It</h2>
              <hr />
              <h4>3 Person project - 5 days</h4>
              <hr />
              {/* Origin */}
              <p>We built a full-stack MERN application with CRUD functionality by making our own back-end and front-end using Express API and React, coordinating our tasks through GitHub and Slack.</p>
              <p></p>
              <hr />
              {/* Added by */}
              <h4><span>🔧</span> Main Technologies Used:</h4>
              <p>JavaScript - HTML5 - CSS - SASS - React - Express - MongoDB - Insomnia - React Bootstrap - Heroku - GitHub</p>
              <hr />
              <div className='btn-flex'>
              <a className='text-dark text-decoration-none' href='https://github.com/verodr/sei65-project-3-app' target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
              <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
              </svg></a>
              <a className='text-dark text-decoration-none btn dark' href='https://ga-read-it.netlify.app/' target="_blank" rel="noopener noreferrer">Website
              </a> 
              </div>
            </Col>
        </Row>
        <Row id="projects-rig">
            <Col md="6">
              <img className='w-100' src="/images/cocktails.png" alt= "Cocktails" />
            </Col>
            <Col md="6" className='all-proj'>
              {/* Description */}
              <h2><span></span>Cocktail Library</h2>
              <hr />
              <h4>In Pairs project - 36h</h4>
                <hr />
              {/* Origin */}
              <p>We were given the task of building a React application that consumes and uses a Public API. Working in pairs we created a cocktails recipes library, where the user can choose their favourite alcoholic spirit and find all matching recipes. The keys to better organize our work were video calls on Zoom, conversations on Slack and commits on GitHub.</p>
              <p></p>
              <hr />
              {/* Added by */}
              <h4><span>🔧</span> Main Technologies Used:</h4>
              <p>JavaScript - SASS- React - Bootstrap - Axios - Insomnia</p>
              <hr />
              <div className='btn-flex'>
              <a className='text-dark text-decoration-none' href='https://github.com/verodr/Project2-API' target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
               <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
              </svg></a>
              <a className='text-dark text-decoration-none btn dark' href='https://cocktail-library.netlify.app/' target="_blank" rel="noopener noreferrer">Website
              </a> 
              </div>
            </Col>
        </Row>
        <Row id="projects-rev" className='game-pro'>
            <Col md="6">
              <img className='w-100' src="/images/game_demo.gif" alt= "Game" />
            </Col>
            <Col md="6" className='all-proj'>
              <h2><span></span>Independence-Day</h2>
              <hr />
              <h4><strong>Solo project - 1 week</strong></h4>
                <hr />
              <p>For my first project with GA, I've created from scratch my customised version of the very famous “Space-Invaders” game, which I called “Independence-Day”, inspired by the homonymous movie (1996). I used HTML, CSS and JavaScript.</p>
              <hr />
              <h4><span>🔧</span> Main Technologies Used:</h4>
              <p>JavaScript - HTML5 - CSS</p>
              <hr />
              <div className='btn-flex'>
              <a className='text-dark text-decoration-none' href='https://github.com/verodr/Project1-Game' target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
              <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
              </svg></a>
              <a className='text-dark text-decoration-none btn dark' href='https://verodr.github.io/Project1-Game/' target="_blank" rel="noopener noreferrer">Website
              </a> 
              </div>
            </Col>
        </Row>
    </Container>
    )
}
     

export default ProjectsPage