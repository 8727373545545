// Import Link

// Import helpers

    import Container from 'react-bootstrap/Container';
    import Nav from 'react-bootstrap/Nav';
    import Navbar from 'react-bootstrap/Navbar';
    import Offcanvas from 'react-bootstrap/Offcanvas';

// Import React Bootstrap Components
const PageNavbar = () => {
      return (
        <>
          {['lg'].map((expand) => (
            <Navbar key={expand} bg="lavender" expand={expand} className="mb-3 navb">
              <Container fluid>
                <Navbar.Brand href="/">VDR</Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton className='nav-collapsed'>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} href="/">
                      VDR
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3 dark">
                      <Nav.Link href="/">Home</Nav.Link>
                      <hr />
                      <Nav.Link href="/about">About Me</Nav.Link>
                      <hr />
                      <Nav.Link href="/projects">My Projects</Nav.Link>
                      <hr />
                      <Nav.Link href="/art">My Art</Nav.Link>
                      <hr />
                      <Nav.Link href="/skills">My Skills</Nav.Link>
                      <hr />
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </>
      );

}

export default PageNavbar