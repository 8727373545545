
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
// import Carousel from 'react-bootstrap/Carousel';

const AboutMe = () => {

    return (
        <Container as="main" className='about-me'>
            <Row>
              <Col md="6">
                <h2>Know Me Better</h2>
                <p>The great technological changes of our time, such as social media and artificial intelligence, have ignited my curiosity in the field of programming. This is why I decided to change my career path starting with the Software Engineering course at General Assembly. During this course, I also identified the tech role which fits me best: full stack developer because I like crafting my projects from the back-end to the front-end. The more creative the work environment, the more it inspires me to give my best. 
                    Furthermore, as it's clear from one of my solo projects (Planet Earth is Calling), I'm also really involved in the cause of Climate Change, so I would love working in an industry committed to the environmental issue, where I could best demonstrate my resilience developed during my studies in law and my analytical skills obtained from my role in customer service.</p>
                <hr />
              </Col>
              <Col md="6">
                <img className='w-100' src="/images/ny_vero.jpg" alt= "Planet" />
              </Col>
            </Row>
            <Row className='travel'>
              <Col md="6">
                <p><span>🌍</span> I love traveling. The place I want to travel is where I've never gone.</p>
                <p><em>"Di una città non apprezzi le sette o settantasette meraviglie, ma la risposta che dà ad una tua domanda".</em></p>
                <p><em>"You do not appreciate the seven or seventy-seven wonders of a city, but the answer it gives to your question".</em> - Italo Calvino</p>
                <hr />
              </Col>
              <Col md="6">
                <img className='w-100' src="/images/paris_vero.jpg" alt= "Planet" />
              </Col>
            </Row>
            <Row className='art'>
              <Col md="6">
                <p><span> 🖌</span> I like to express my passion for art by trying my hand at paintings on canvas and portraits on electronic boards (available <Link to = "/art">here</Link>). Among my favorite artists, you can find: Michelangelo, Édouard Manet, Claude Monet, René Magritte, Gustav Klimt and Pablo Picasso. </p>
                <hr />
                <p><span>🪷</span> One of my greatest inspiration comes from the nature that surrounds us.</p>
                <p><em>"Look deep into nature, and then you will understand everything better".</em> — Albert Einstein</p>
                <hr />
              </Col>
              <Col md="6">
                <img className='w-100' src="/images/nature.jpg" alt= "Planet" id = "nature"/>
              </Col>
                <hr />
  
        </Row>
    </Container>
    )
}

export default AboutMe